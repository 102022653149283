<template>
  <div>
    <v-snackbar
      :timeout="3000"
      v-model="response"
      absolute
      top
      right
      tile
      :color="respType"
    >
      <strong>{{ message }}</strong>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "ResponseMessage",
  props: {
    responseType: {
      default: "",
      type: String,
    },
    showResponse: {
      default: false,
      type: Boolean,
    },
    responseMessage: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      message: this.responseMessage,
      respType: this.responseType,
      response: this.showResponse,
    };
  },
};
</script>

<style scoped></style>
